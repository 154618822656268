import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de color variable però sempre dins els tons violacis. El capell fa de 6 a 12 cm de diàmetre, primer convex per passar a quasi pla. El marge està enrotllat quan és jove i freqüentment alçat de vell. Les làmines són primes i atapeïdes, fàcilment separables del peu. Aquest és cilíndric, elàstic, de 5-10 x 1-3 cm que s’eixampla a la base i amb la superfície un poc cotonosa. Les espores són liles o rosades en massa, el·líptiques, un poc berrugoses, de 6,5-8 x 3-5,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      